import { skipToken, useSuspenseQuery } from '@apollo/client'
import { invariant } from '@apollo/client/utilities/globals'
import {
  Brand,
  Query,
  RetailerAccount,
  User,
} from '@promoboxx/graphql-gateway-types'
import {
  createContext,
  memo,
  startTransition,
  useContext,
  useMemo,
  useRef,
} from 'react'
import { useRouteMatch } from 'react-router'

import { finishSetup } from '@src/config/I18n'
import useRetailerAccountSlugFromRoute from '@src/lib/useRetailerAccountSlugFromRoute'

import { useJWT } from './JwtProvider'
import parsePbxxJwt from './parsePbxxJwt'
import {
  GET_RETAILER_ACCOUNT,
  GET_USER,
  RETAILER_ACCOUNT_FIELDS,
  USER_FIELDS,
  USER_FIELDS_WITH_RETAILER_ACCOUNT_FIELDS,
} from './queries'

interface AuthContext {
  currentUser?: User | null
  ensureCurrentUser: User
  refetchCurrentUser: () => Promise<User | null | undefined>

  currentRetailerAccount?: RetailerAccount | null
  ensureCurrentRetailerAccount: RetailerAccount
  brands: Brand[]
  refetchRetailerAccount: () => Promise<RetailerAccount | null | undefined>

  retailerAccounts: RetailerAccount[]
}

export const authContext = createContext<AuthContext | undefined>(undefined)

export function useAuthContext() {
  const context = useContext(authContext)
  invariant(context, 'useAuthContext must be used within an AuthProvider')
  return context
}

const AuthProvider: React.FC<React.PropsWithChildren> = (props) => {
  const routeNeedingAllRetailerInfoMatch = useRouteMatch([
    // Enroll page checks retailers for a matching brand slug.
    '/enroll/*',
    // Redirect page checks retailers for a matching brand slug.
    '/redirect/*',
    // Similar to redirect page, public campaign page potentially redirects to
    // a retailer account.
    '/view-campaign/*',
  ])
  // Using a ref because we don't actually want this value to change. If it
  // does, we just end up re-querying the user when the difference is really
  // down to the retailer account fields.
  // Intentionally never update it.
  const shouldLoadRetailerAccountFields = useRef(
    Boolean(routeNeedingAllRetailerInfoMatch),
  )

  const jwtContext = useJWT()
  const parsed = parsePbxxJwt(jwtContext.jwt)
  const retailerAccountSlug = useRetailerAccountSlugFromRoute()

  const { data: userData, refetch: refetchUser } = useSuspenseQuery<Query>(
    GET_USER,
    parsed?.sub
      ? {
          variables: {
            id: parsed.sub,
            fields: shouldLoadRetailerAccountFields.current
              ? USER_FIELDS_WITH_RETAILER_ACCOUNT_FIELDS
              : USER_FIELDS,
          },
          errorPolicy: 'all',
        }
      : skipToken,
  )

  const { data: retailerAccountData, refetch: refetchRetailerAccount } =
    useSuspenseQuery<Query>(
      GET_RETAILER_ACCOUNT,
      // We only want to fetch the retailer account if all these conditions are
      // met:
      // - We have a user.
      // - We have a retailer account slug.
      // - The retailer account doesn't exist in the user's retailer accounts,
      //   when we're already fetching all retailer account fields.
      userData?.getUser &&
        retailerAccountSlug &&
        !(
          shouldLoadRetailerAccountFields.current &&
          userData.getUser.retailer_accounts?.some(
            (x) => x.slug === retailerAccountSlug,
          )
        )
        ? {
            variables: {
              id: retailerAccountSlug,
              fields: RETAILER_ACCOUNT_FIELDS,
            },
            errorPolicy: 'all',
          }
        : skipToken,
    )

  const contextValue = useMemo(() => {
    const currentUser = userData?.getUser
    const fetchedRetailerAccount = retailerAccountData?.getRetailerAccount

    // Potentially add the fetched retailer account to the main list of
    // retailer accounts.
    let didFindFetchedRetailerAccount = false
    const retailerAccounts = (currentUser?.retailer_accounts || []).map((x) => {
      if (fetchedRetailerAccount) {
        if (String(x.id) === String(fetchedRetailerAccount.id)) {
          didFindFetchedRetailerAccount = true
          return fetchedRetailerAccount
        }
      }

      return x
    })
    if (!didFindFetchedRetailerAccount && fetchedRetailerAccount) {
      retailerAccounts.push(fetchedRetailerAccount)
    }

    // Sort retailers by their brand name.
    const sortedRetailerAccounts = retailerAccounts.map((x) => {
      const editable: RetailerAccount = { ...x }

      editable.retailers = [...(editable.retailers || [])]
      editable.retailers.sort((a, b) => {
        return (a.brand?.name || '').localeCompare(b.brand?.name || '')
      })

      return editable
    })

    // Sort retailer accounts by their name.
    sortedRetailerAccounts.sort((a, b) => {
      return (a.name || '').localeCompare(b.name || '')
    })

    // Now we can build the list of brands, which will be sorted by their
    // names.
    const brands: Brand[] = []
    let currentRetailerAccount: RetailerAccount | undefined
    if (retailerAccountSlug) {
      currentRetailerAccount = sortedRetailerAccounts.find(
        (x) => x.slug === retailerAccountSlug,
      )

      if (currentRetailerAccount) {
        for (const retailer of currentRetailerAccount.retailers || []) {
          if (retailer.brand) {
            brands.push(retailer.brand)
          }
        }
      }
    }

    const contextValue: AuthContext = {
      currentUser,
      get ensureCurrentUser() {
        invariant(currentUser, 'No user found')

        return currentUser
      },
      refetchCurrentUser: () => {
        // This is a little funky.
        // We want the signature of refetch to just be:
        // `() => Promise<string | undefined>`
        // simple enough with an async function, but we need to use
        // `startTransition` to avoid triggering the suspense boundary.
        // And `startTransition` neither returns the result of the passed
        // function nor accepts an async function.
        let resolve: (value: User | null | undefined) => void

        let promise = new Promise<User | null | undefined>((r) => {
          resolve = r
        })

        startTransition(() => {
          refetchUser().then(({ data }) => {
            const user = data?.getUser
            resolve(user)
          })
        })

        return promise
      },

      currentRetailerAccount,
      get ensureCurrentRetailerAccount() {
        invariant(currentRetailerAccount, 'No retailer account found')

        return currentRetailerAccount
      },
      refetchRetailerAccount: () => {
        // This is a little funky.
        // We want the signature of refetch to just be:
        // `() => Promise<string | undefined>`
        // simple enough with an async function, but we need to use
        // `startTransition` to avoid triggering the suspense boundary.
        // And `startTransition` neither returns the result of the passed
        // function nor accepts an async function.
        let resolve: (value: RetailerAccount | null | undefined) => void

        let promise = new Promise<RetailerAccount | null | undefined>((r) => {
          resolve = r
        })

        startTransition(() => {
          refetchRetailerAccount().then(({ data }) => {
            const retailerAccount = data?.getRetailerAccount
            resolve(retailerAccount)
          })
        })

        return promise
      },

      brands,

      retailerAccounts: sortedRetailerAccounts,
    }

    return contextValue
  }, [
    userData,
    retailerAccountData,
    retailerAccountSlug,
    refetchUser,
    refetchRetailerAccount,
  ])

  finishSetup(userData?.getUser?.language_preference)

  return (
    <authContext.Provider value={contextValue}>
      {props.children}
    </authContext.Provider>
  )
}

export default memo(AuthProvider)
