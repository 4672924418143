import { helpWidget } from '@src/lib/HelpWidget'

import AppConfig from '../AppConfig'

import { Service } from './index'

const zendesk: Service<typeof helpWidget> = {
  init() {
    if (
      !AppConfig.ZENDESK_WEB_WIDGET_ID ||
      new URL(window.location.toString()).searchParams.get(
        'suppress_overlays',
      ) === 'true'
    ) {
      return Promise.resolve()
    }

    const script = document.createElement('script')
    script.src = `https://static.zdassets.com/ekr/snippet.js?key=${AppConfig.ZENDESK_WEB_WIDGET_ID}`
    script.type = 'text/javascript'
    script.id = 'ze-snippet'

    document.head.appendChild(script)

    return new Promise((resolve) => {
      script.onload = () => {
        window.zEACLoaded = true
        if (AppConfig.ENV !== 'production') {
          window.zE?.('webWidget', 'hide')
        }
        resolve()
      }
    })
  },
  loaded: () => {
    return window.zEACLoaded !== undefined
  },
  instance: () => {
    return helpWidget
  },
  identify: (user) => {
    const tags = ['Retailer']

    window.$zopim?.livechat.setName(user.name)
    window.$zopim?.livechat.setEmail(user.email)
    window.$zopim?.livechat.setLanguage(user.languagePreference)
    window.$zopim?.livechat.addTags(...tags)

    window.zE?.('webWidget', 'identify', {
      name: user.name,
      email: user.email,
    })
    window.zE?.('webWidget', 'setLocale', user.languagePreference)
    window.zE?.('webWidget', 'chat:addTags', ...tags)

    window.zE?.('webWidget', 'prefill', {
      name: {
        value: user.name,
      },
      email: {
        value: user.email,
      },
    })
  },
}
export default zendesk
